export default {
    env_home_topper: {
        name: 'no_name',
        title: 'Lightning Fast with',
        headline: 'UNLIMITED DATA!',
        sub_headline: "With great data comes great responsibility... and that's why we've given YOU the power of unlimited data-just choose your max data speed!",
        lead: 'Personal mobile phone use only. Fair use policy applies.',
    },

    env_home_profits: {
        name: '',
        title: '',
        headline: 'Get started with your new Mighty Mobile plan',
        lead: '',
    },

    env_home_botter: {
        name: '',
        title: '',
        headline: 'Swing Into Action!',
        lead: "Order your Mighty Mobile SIM today and we'll deliver it straight to your front door! You'll be up and running in no time!",
    },

    env_welcome: {
        name: '',
        title: 'Welcome to Mighty Mobile!',
        headline: 'Sign in or create an account using the options below.',
        sub_headline: 'Its great to have you as a part of the team',
        lead: 'With just a few simple clicks, we’ll have you setup and ready to go in no time',
    },

    env_help_center: {
        name: 'FAQs',
        title: '',
        headline: '',
        lead: 'Welcome to our help center, please select what application you want help using or type in your question below.',
        faq_section_name: 'FAQs',
    },

    env_about: {
        name: 'About Us',
        title: '',
        headline: '',
        lead: '',
        content: '<p><strong>Kia ora. Welcome to Mighty Mobile!</strong></p> <p>Introducing Mighty Mobile, New Zealand’s home for Prepay mobile plans with UNLIMITED data!</p> <p>With great data comes great responsibility… and that’s why we’ve given YOU the power of true, unlimited data - just choose your data speed! No more worrying about throttled ‘endless’ data or hitting your cap just when you need it the most, now you can experience lightning fast download speeds and unlimited data forever!</p> <p>Making the switch is easy, you can even bring your existing number across. Just swing by <a href="https://www.mightyape.co.nz/" target="_blank">MightyApe.co.nz</a> and order a Prepay plan - your new SIM will be delivered mighty fast! Once you’ve received your SIM you can activate it - in minutes - and unleash the power of UNLIMITED data!</p> <p>You’ve experienced the fastest delivery in New Zealand with Mighty Ape, now embrace the speed of the greatest mobile plans ever to be assembled - Join us today!</p>'
    },

    env_international_plans: {
        name: '',
        title: '',
        headline: 'Get great calling and SMS rates to selected destinations',
        lead: '',
        description: 'International call charges are not included in plans or addons, please review the out of bundle charges here:',
    },

    env_privacy: {
        name: '',
        title: 'Privacy Policy',
        headline: '',
        lead: '',
    },

    env_terms: {
        name: 'Prepay plan Terms and Conditions',
        title: '',
        headline: '',
        lead: '',
    },

    env_monthly_top_up_terms: {
        name: '',
        title: '',
        headline: '',
        lead: '',
        content: '',
    },

    env_prepay_terms: {
        name: '',
        title: '',
        headline: '',
        lead: '',
        content: '',
    },

    env_packs_and_combos_terms: {
        name: '',
        title: '',
        headline: '',
        lead: '',
        content: '',
    },

    env_select_account: {
        name: '',
        title: '',
        headline: 'Multiple customer accounts',
        lead: 'Select which account you want to sign in with.',
    },

    env_package: {
        name: 'Package',
        title: '',
        headline: '',
        lead: '',
    },

    // common data if no data for specific plans
    env_plans: {
        name: 'Mighty Mobile plans',
        title: 'Find a plan to suit you',
        headline: '',
        lead: 'Check out the plans below and see what amazing deals we have on offer',
    },

    env_solo_plans: {
        name: 'Individual plans',
        title: '',
    },
    
    // data for Postpay plans
    env_plans_postpay: {
        name: '',
        title: '',
        headline: '',
        lead: '',
    },
    
    // data for Prepay plans
    env_plans_prepay: {
        name: '',
        title: '',
        headline: '',
        lead: '',
    },
    
    // data for PAYG plans
    env_plans_payg: {
        name: '',
        title: '',
        headline: '',
        lead: '',
    },

    env_sign_in: {
        name: '',
        title: 'Sign in',
        welcome_title: 'Welcome to Mighty Mobile!',
        headline: '',
        lead: 'Welcome back',
    },

    env_sign_up: {
        name: '',
        title: '',
        headline: 'Join Mighty Mobile right now',
        lead: '',
    },

    env_sign_up_with_identity_link: {
        name: '',
        title: '',
        headline: 'Identity Link',
        lead: 'Create a login for your account using any of the options below. You can use a login you already have or create a new one to associate your login credentials with this account.',
    },

    env_forgot_email: {
        name: '',
        title: '',
        headline: 'Forgot email?',
        lead: 'Please enter your current phone number, we’ll send you a SMS with your login details.',
    },

    env_forgot_pass: {
        name: '',
        title: '',
        headline: 'Forgotten your password?',
        lead: 'Please enter the email address used for sign up and we’ll send you a link to change your password.',
    },

    env_one_time_code: {
        name: '',
        title: '',
        headline: 'Enter One-Time Password',
        lead: 'Enter the code you received via email.',
    },

    env_new_pass: {
        name: '',
        title: '',
        headline: 'Create new password',
        lead: '',
    },

    env_profit: {
        '1': {
            title: 'Unlimited Calls & TXTS',
            text: 'Go bananas unlimited voice calls and TXTS to standard NZ and AUS numbers (eg no short codes on MMS/PXT). Personal mobile phone use only. Mobile terms apply.',
        },
        '2': {
            title: 'Mighty Fast',
            text: 'Our fastest plan is at full throttle! Actual speeds experienced may vary due to several factors, including your device capabilities, location, network congestion, and network coverage.',
        },
        '3': {
            title: 'Great Service',
            text: 'Our customer service team is here to help you have the best mobile: experience possible! If you need. assistance with your Mighty Mobile service, don’t hesitate to get in touch via live chat.',
        },
    },

    env_theme: {
        prepay_help_me_choose_message: 'Purchase addons and plans with Prepay!',

        // Adopt by Mobile
        sim_id_text: 'SIM Number',
        adopt_subtitle: 'To complete activation, please enter the SIM number that you can find on the back of your new SIM card. Then make sure the SIM is inserted into your phone so you can receive a message to complete your activation.',

        // Checkout Note
        checkout_note: 'Payment details entered during sign up will also be used for recurring billing charges. Your credit card will only be charged when you activate your SIM.',
        checkout_note_for_your_service: '',
        checkout_note_esim: 'Payment details entered during sign up will also be used for recurring billing charges. Your credit card will only be charged when you activate your SIM.',
        checkout_note_only_sim: 'You won’t be charged until your SIM arrives and you activate your plan.',

        // PAYMENT METHODS
        payment_methods_title: 'Payment Methods',

        // Home page - Plans
        home_plans_headline: '',

        // FOOTER LINKS: title => link | title => link 
        footer_links: 'Terms and Conditions => terms | Privacy Policy => privacy',
    },

    my_app_home: 'My Account',

    receive_newsletter:                 `I want to receive newsletter from ${process.env.VUE_APP_NAME ?? ''}`,
    receive_offers:                     `I want to receive special offers notifications from ${process.env.VUE_APP_NAME ?? ''}`,
    receive_offers_and_newsletters:     `I want to receive special offers and newsletters from ${process.env.VUE_APP_NAME ?? ''}`,
    we_successfully_placed_your_order:  `We have successfully placed your order{order}. To check your order status, login to the ${process.env.VUE_APP_NAME?.split(' ')[0] || ''} app and go to “order history” to manage your orders`,

    Welcome_to_family:                  `Welcome to the ${process.env.VUE_APP_NAME ?? ''} family!`,
    head_to_your_profile:               'Head to your Order History to track your SIM delivery and keep an eye out in your inbox for your order confirmation.',
    you_can_go_to_the_sim_swap:         'After receiving your new SIM card, you can go back to the SIM swap flow and complete it.',

    youll_be_able_to_find_eSIM_QR:      "You’ll be able to find your eSIM QR code in your confirmation email and in the Order History section of your account.",

    sim: {
        select_sim_type_step: {
            title: "Please choose a SIM type",
            // subtitle: `We will send you your brand new ${process.env.VUE_APP_MIDDLE_NAME} SIM card in the mail!`
        },
        thank_you_step: {
            title: 'Good choice!',
            only_sim_title: 'Thank you for your purchase',
            subtitle: '',
            esim_subtitle: '',
            esim_leed: '',
            help_title: '',
            help_subtitle: '',
        },
    },

    scan_this_qr_code:                  'Please scan this QR code using your mobile phone to install eSIM.',

    customer_account_ID:                `${process.env.VUE_APP_MIDDLE_NAME || 'service'} Account Number`,
    customer_account_ID_tooltip:        `Your ${process.env.VUE_APP_MIDDLE_NAME || 'service'} account number starts with a 5XXXXXXX`,
    external_ID_promt: {
        title: `Do you have a ${process.env.VUE_APP_MIDDLE_NAME || 'service'} account?`,
        subtitle: `Enter your ${process.env.VUE_APP_MIDDLE_NAME || 'service'} account number if you have one.`,
        input_placeholder: `${process.env.VUE_APP_MIDDLE_NAME || ''} account number`,
        note: `If you have agreed to receiving special offers from time to time from ${process.env.VUE_APP_MIDDLE_NAME || 'us'}, adding your account number helps us send you messages that are more relevant to you.`,
    },
    true_rewards_number:                "True Rewards Number",
    true_rewards_account:               `${process.env.VUE_APP_NAME?.split(' ')[0] || 'Your'} Rewards Account`,
    sign_into_rewards:                  `Sign into ${process.env.VUE_APP_MIDDLE_NAME || 'your'} Rewards`,

    payment_details_lead:               `Thank you for using ${process.env.VUE_APP_NAME?.split(' ')[0] || 'our service'}, we hope you have enjoyed your services. We will deduct the invoice amount from your nominated default credit card on the invoice due date. You can also go into the application and pay this manually yourself.`,

    env_purchase_or_switch_confirmation: 'Purchase confirmation',
    confirm_change_plan_now:            'We will change your plan to <strong>"{name}"</strong> immediately. No pro ration and you will be charged accordingly <strong>now</strong>. Any prior discounts will be removed.',
    confirm_change_plan_later:          'We will change your plan to the <strong>"{name}"</strong> when your current plan is due to renew. Charges for new plan start when it is activated.',

    sim_transfer_product_types: {
        postpay: {
            title: 'Postpay / On Account',
            features: [
                'Each time you make a purchase, you are invoiced for your purchase',
                'You are charged up to 15 days after your purchase',
                'It is up to you to maintain your spending within your budget, and to ensure there are funds on your chosen payment method when payment is due, otherwise your account may enter into arrears and may be subject to suspension',
            ],
        },
        prepay: {
            title: 'Prepay',
            features: [
                'Each time you make a purchase, you are invoiced and charged immediately',
                'This allows you to budget your spending as you go, and don’t need to worry about accumulating amounts owing on invoices',
                'To switch to Prepay billing, you must pay any outstanding amounts owing',
            ],
        },
        payg: {
            title: 'Pay as you go / Subscription',
            features: [
                'Each time you make a purchase, you are invoiced and charged immediately',
                'You pay for any out of bundle charges up to a few days after they have been made, and don’t need to worry about accumulating large amounts owing on invoices',
                'To switch to PAYG/Subscription billing, you must pay any outstanding amounts owing',
            ],
        },
    },

    real_sim_title:                  'Physical SIM',
    real_sim_subtitle:               'All physical SIMs fit standard, micro and nano trays. Your SIM will be sent to you in the mail.',
    e_sim_title:                     'eSIM',
    e_sim_subtitle:                  'Order an eSIM which you can download right away.',

    make_sure_phone_supports_e_sim:  'Please make sure your mobile phone supports eSIM before purchasing an eSIM',

    // Sims Companion Plans
    sims_multi_plans: {
        family: 'Family',
        family_title: 'Family Plans',
        family_subtitle: 'SIM cards that are grouped together and charged to the key account holder',
        family_info: `
            <p class="family-info__title">Users who enjoy family plans can look forward to group mobile discounts and easily roll up their bills into one easy to manage bill.</p>
            <p class="family-info__subtitle">The key account holder is responsible for paying for and managing their mobile plan group. They can easily add new members, order new SIMs for thier plan or remove those that no longer wish to join this great deal.</p>
        `,

        companion: 'Companion',
        companion_title: 'Companion Plans',
        companion_subtitle: 'SIM cards that are grouped together and charged seperately',
        companion_info: `
            <p class="companion-info__title">You can share a companion plan with up to 6 of your friends where all your mobile plans are grouped together and charged seperately.</p>
            <div class="companion-info__condition--container">
                <p class="companion-info__condition--title">Groups of under 3 people:</p>
                <p class="companion-info__condition--value">$45 per person, per month</p>
            </div>
            <div class="companion-info__condition--container">
                <p class="companion-info__condition--title">Groups 3 or over:</p>
                <p class="companion-info__condition--value">$35 per person, per month</p>
            </div>
            <p class="companion-info__special">The maximum amount of people you can have in your companion group at any given time is 6 people.</p>
        `,

        solo: 'Individual',
        solo_title: 'Individual Plans',
        solo_subtitle: 'Select a plan{s} and SIM type{s}',
        solo_subtitle_not_multi: 'Pick the plan that suits you',
        solo_info: '',

        simple: 'Individual',
        simple_title: 'Individual Plans',
        simple_subtitle: 'Select a plan{s} and SIM type{s}',
        simple_subtitle_not_multi: 'Pick the plan that suits you',
        simple_info: '',

        sim_purchase_title: 'Your Cart',
    },

    sims_advanced_options_footnotes: {
        SelfManaged: 'When enabled, this SIM will be linked to your login account for activation and self-service management. If someone else will activate and use this SIM, then please leave this unchecked.',
        UseMasterBillingAccount: 'You will be responsible for all charges associated to this SIM. You can edit this spend limit or remove this from your billing account at any time.',
        Grouped: 'SIM will be added to a new or existing group mobile plan and prices are subject to change based on group plan memberships.',
    },
}
